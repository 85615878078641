import { PAGES } from "@/data/types/pages";

export const IFRAME_URL = PAGES.VERIFY_PAYMENT;
export const GET_LEGAL_INFO = "getLegalInfo";
export const PAYMENT_IFRAME_ID = "payment-iframe";
export const GET_PROVIDER_ERROR = "getProviderError";
export const APPLICABLE_PRICE = "applicablePrice";
export const PAYMENT_SUCCESS = "paymentSuccess";
export const PAYMENT_ERROR = "paymentError";
export const INIT_PAYMENT_PROCESS = "initPaymentProcess";
export const PROVIDER = "provider";
export const MAX_RETRIES = 25;

export enum CurrencyMap {
  "USD" = "$",
  "EUR" = "€",
}

export enum PaymentType {
  "PAYMENT" = "payment",
  "REFUND" = "refund",
}
